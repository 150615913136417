import { createApp } from 'vue'
import App from './App.vue'
// https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#configure-your-application
// https://stackoverflow.com/questions/56455901/is-it-possible-to-use-the-amplify-framework-without-using-the-cli
import {Amplify} from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_a0TmIsW0I',

              // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '5971010b7ruac2jr88s72t647c',
     
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        
   
         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'https://enklsound-sandbox.auth.us-east-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid'],
            redirectSignIn: 'https://sample-app-9cy.pages.dev/',
            redirectSignOut: 'https://sample-app-9cy.pages.dev/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});


createApp(App).mount('#app')
